#works {
  margin: 6rem auto;
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.worksTitle {
  margin: 1rem 0;
  font-size: 3rem;
}

.worksDesc {
  font-weight: 300;
  font-size: 1.2rem;
  max-width: 45rem;
  margin: 1rem;
}

.worksCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
}

.projectCard {
  background-color: var(--cards-background-color);
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 10px var(--secondary-box-shadow);
  border-radius: 1rem 1rem !important;
  text-align: center;
  margin: 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectCard:hover {
  box-shadow: 5px 5px 10px var(--shadow-cards);
}

.projectImg {
  border-radius: 1rem 1rem 0 0;
  object-fit: cover;
  height: 15rem;
  margin-bottom: 1rem;
  width: 100%;
}

.projectTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--cards-background-color);
}

.projectDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 20rem;
  margin: 0 auto;
  background-color: var(--cards-background-color);
}

.projectButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1rem;
  background-color: var(--cards-background-color);
}

.projectButton {
  border: 1px solid var(--borders) !important;
  box-shadow: 0 0 5px var(--secondary-box-shadow);
  height: 2rem;
  width: 7.2rem;
  flex: 1;
  background: var(--button-background) !important;
  color: var(--button-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s;
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0.8rem;
  flex-direction: row;
  text-align: center;
  background-color: var(--button-background-color);
}

.projectButton:hover {
  box-shadow: 0 0 15px #bd83b8;
  cursor: pointer;
}

.project_icon {
  width: 1.09rem !important;
  margin-right: 0.3rem;
  background-color: var(--cards-background-color);
}

.dialog-title {
  color: white;
  justify-content: center;
}

.dialog-content {
  color: white;
}

/*mobile devices break points*/
@media screen and (max-width: 585px) {
  .projectImg {
    height: 48vw;
    width: 100%;
  }

  .projectButton {
    font-size: 0.6rem;
    margin: 0.5rem;
    padding: 0.7rem 0.8rem;
  }

  .project_icon {
    width: 0.9rem !important;
    height: 0.9rem !important;
    margin-right: 0.3rem;
  }
}

/*tablets break points*/
@media screen and (max-width: 680px) {
  .projectImg {
    height: 48vw;
    width: 100%;
  }

  .projectButton {
    font-size: 0.8rem;
    margin: 0.5rem;
    padding: 0.7rem 0.8rem;
  }

  .project_icon {
    width: 1rem !important;
    height: 1rem !important;
    margin-right: 0.3rem;
  }
}

/*Smaller devices*/
@media screen and (max-width: 320px) {
  .projectImg {
    height: 48vw;
    width: 100%;
  }

  .projectCard {
    width: 75%;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
  }

  .projectButton {
    font-size: 0.5rem;
    margin: 0.5rem;
    padding: 0.7rem 0.8rem;
  }

  .project_icon {
    width: 0.77rem !important;
    height: 0.77rem !important;
    margin-right: 0.2rem;
  }
}
