#skills {
  overflow: hidden;
  width: 100vw;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.skillTitle {
  font-size: 3rem;
  font-weight: 600;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}

.skillDesc {
  font-weight: 400 !important;
  font-size: 1.2rem;
  max-width: 51rem;
  padding: 0 2rem;
}

.skillBars {
  margin: 1.5rem;
  width: 100vw;
  max-width: 80%;
  text-align: left;
}

.skillBar {
  display: flex;
  margin: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  background-color: var(--cards-background-color);
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 10px var(--secondary-box-shadow);
}

.skillBar:hover {
  box-shadow: 3px 3px 10px var(--shadow-cards);
}

.skillBarImg {
  object-fit: cover;
  height: 6rem;
  width: 6rem;
  margin-right: 2rem;
  background-color: var(--cards-background-color);
}

.skillBarText {
  display: flex;
  flex-direction: column;
  background-color: var(--cards-background-color);
}

.skillBarText > p {
  font-size: 1rem;
  background-color: var(--cards-background-color);
}

.skillBarText > h2 {
  background-color: var(--cards-background-color);
}

@media screen and (max-width: 720px) {
  .skillBarText > p {
    font-size: 2.5vw;
  }
}

 /*mobile devices break points*/
@media screen and (max-width: 585px) {
  .skillBars {
    flex-direction: column;
    align-items: center; 
  }

  .skillBar {
    flex-direction: column;
    align-items: center; 
  }

  .skillBarImg {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    margin-top: -0.5rem;
    margin-right: 0;
  }

  .skillBarText > h2 {
    font-size: 4.5vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
