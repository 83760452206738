#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative
}

.bg {
    position: absolute;
    top: 1rem;
    right: 0;
    object-fit: cover;
    height: 60vh;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: var(--secondary-text); 
}

.title {
    font-weight: 600;
    font-size: 2.5rem;
}

.introPara {
    font-size: 1.2rem;
    font-weight: 100;
}

.btn {
    border: 2px solid var(--borders);
    box-shadow: 0 0 10px var(--secondary-box-shadow);
    height: 3rem;
    margin: 1rem 0;
    background: var(--button-background);
    color: var(--button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    border-radius: 2rem;
    transition: box-shadow 0.3s;
    font-weight: 600;
    font-size: 1.05rem;
}

.btn:hover {
    box-shadow: 0 0 15px var(--shadow-buttons);
}

.resume-icon {
    background-color: var(--button-background);
}
  
/*writing media queries to make the components responsible */
@media screen and (max-width: 840px) {
    .bg {
        right: -10vw;
    }
    .introContent {
        font-size: 10vw;
    }

    .hello {
        font-size: 4.5vw;
    }
}

@media screen and (max-width: 480px) {
    .bg {
        right: -20vw;
    }
}