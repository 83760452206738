.navbar {
    background-color: var(--background);
    height: 6rem;
    width: 100vw;  /*viewing-width */
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;  
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
    font-size: 1.1rem;
}

.desktopMenuListItem:hover {
    color: var(--shadow-buttons);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid var(--shadow-buttons);
}

.desktopMenuButton {
    border: 2px solid var(--borders);
    box-shadow: 0 0 10px var(--secondary-box-shadow);
    height: 3rem;
    background: var(--button-background);
    color: var(--button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    transition: box-shadow 0.3s;
    font-weight: 600;
    font-size: 1.05rem;
}

.desktopMenuButton:hover{
    box-shadow: 0 0 20px var(--shadow-buttons);
    cursor: pointer;
}

.active {
    color: var(--secondary-text);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid var(--secondary-text);
}

.chat-icon {
    background-color: var(--button-background);
}

.mobMenu {
    display: none !important;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: var(--background);
    border: 1px solid var(--borders);
    box-shadow: 0 0 8px var(--borders);
}

.listItem {
    color: var(--text-primary);
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: var(--background);
    cursor: pointer;
}

.modeType {
    cursor: pointer;
}

@media screen and (max-width: 720px) {
    .mobMenu {
        display: flex !important;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuButton {
        display: none;
    }  

}

@media screen and (min-width: 721px) {
    .mobMenu {
        display: none !important;
    }
    .navMenu {
        display: none !important; 
    }
    .desktopMenu {
        display: flex;
    }
    .desktopMenuButton {
        display: flex;
    }  
}