#contactPage {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    font-weight: 600;
    border-radius: 0.5rem;
    background: #dee8ff;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.name:focus, .email:focus, .msg:focus {
    box-shadow: 0 0 20px var(--shadow-buttons); 
}


.submitBtn {
    border: 2px solid var(--secondary-box-shadow);
    box-shadow: 0 0 10px var(--secondary-box-shadow);
    height: 3rem;
    background: var(--button-background);
    color: var(--button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2.5rem;
    border-radius: 2rem;
    transition: box-shadow 0.3s;
    font-weight: 600;
    font-size: 1.05rem;
    margin: 2rem;
}


.submitBtn:hover{
    box-shadow: 0 0 15px var(--shadow-buttons);
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    margin: 0 0.75rem;
}

.socialMedia {
    color: white;
    text-decoration: none;
}

.socialMedia:active {
    color: var(--shadow-buttons);
}
